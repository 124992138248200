<template>
    <div :class="CSSClasses" class="button-block">
        <CarnegieButton v-bind="button" :theme="themeColor" :pageLanguage="pageLanguage" />
    </div>
</template>

<script setup>
const { pageLanguage } = usePageDataStore();
const props = defineProps({
    size: {
        ...sizes,
    },
    button: {
        Object,
    },
});
const themeColor = inject('tierTheme', 'light');
const CSSClasses = [`theme-${themeColor}`, `size-${props.size}`];
</script>

<style lang="scss" scoped>
.button-block {
    text-align: center;
}
</style>
